
.tree-view{
  .indicator{
    width: 10px;
    margin-right: 5px;
    font-size: 12px;
    text-align: center;
    padding: 9px 1px 1px 1px;
    cursor: pointer;
  }
  .label{
    width: calc(100% - 15px);
  }
  .children{
    padding-left: 15px;
    width: 100%;
  }
}