@import '../../scss/variables.scss';  
.action{
  float: right;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding-right: 5px;
  padding-top: 5px;
  cursor: pointer;
  &:hover, &:focus{
    background: lightblue;
  }
  // &.edit{
  //   opacity: 0;
  //   &:hover, &:focus{
  //     opacity: 1;
  //   }
  // }
  svg{
    fill: currentColor;
    float: right;
    color: $active-background-color
  }
}