@import 'scss/variables.scss';
.tms-link{
  // width: 100%; 
  text-align: center;
  button {
    background-color: #fff; 
    border: 0; 
    color: $link-color;//$base-color;// $active-background-color;
    border-bottom: 1px solid transparent; 
    padding: 1px 1px 0 1px; 
    width: fit-content;
    text-align: left;
    &:hover{
      border-bottom: 1px solid $base-color;// $active-background-color; 
    }
  }
}

.tms-link2 {
  display: inline-block;
  a {
    background-color: transparent; 
    border: 0; 
    color: $link-color;
    padding: 1px 1px 0 1px; 
    text-align: left !important;
    text-decoration: underline;
    cursor: pointer;
    &.disabled{
      color: #595959;
      text-decoration: none;
    }
  }
  button {
    background-color: transparent; 
    border: 0; 
    color: $link-color;
    padding: 0 1px 0 1px; 
    width: fit-content;
    text-align: left;
    text-decoration: underline;
  }
}

.tms-checkbox {
  display: flex;

  .container {
    flex: 0 0 16px;
    display: flex;
    position: relative;
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      left: -5px;
      
      &:focus ~ .checkmark {
        border-color: $focus-outline-color;
      }
      &:checked ~ .checkmark {
        background-color: #1A1A1A;//#fff;	
        // color: rgb(102,102,102);

        &:after {
          display: block;
          left: 5px;
          top: 1px;
          width: 8px;
          height: 12px;
          border: solid #fff;//rgb(102,102,102);
          border-width: 0px 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      &.disabled ~ .checkmark {
        background-color: #595959;
    }
    }

    .checkmark {
      position: absolute;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: #fff;
      border: 1.5px solid #000;//rgb(102,102,102);

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  .display {
    flex: 1 1 auto;
  }
}

.new-indicator {
  display: inline-block;
  background-color: #2B8737;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 5px;
  margin-bottom: -2px;
}
.tms-title{
  .tms-title-label{
    font-size: 13px;
  }
}