.dropdown-item{
  &.selected{
    background-color: rgba(51,132,245,39%);
    img{
      width: 15px;
      position: absolute;
      left: 5px;
      margin-top: 8px;
    }
  }
}