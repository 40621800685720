.mentor-service-pops{
  .e-modal{
    z-index: 5001;
  }
  .e-modal-dialog.mentor-service-pops-modal{
    top: 5%;
    max-height: 90%;
    height: 415px;
    display: flex;
    flex-direction: column;
    padding: 30px;    
    
    &.step_EC001, &.step_EM0003, &.step_VM3008, &.step_VM3007{
      height: 245px;
    }
    .image-warning{
      margin: 0 5px 0 -10px;
      width: 24px;
    }
    .mentor-service-pops-modal-container{
      height: 100%;
      padding: 0px 10px 0 20px;    
    }
    width:100%;
    @media (min-width: 730px){
      width:730px;
    }
  }
}