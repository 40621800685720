.ods-dropdown{
	width: 100%;
  .dropdown-toggler{
		margin: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #fff;
		border: 2px solid #1a1a1a;
		padding: 0.625rem 1rem;
		color: #1a1a1a;
		.fa-caret-down{
			margin: 5px 5px 0 0;
			font-size: 16px;
		}
	}
}