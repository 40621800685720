.auth{
  background: #eee;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  .try{
    color: #007bff;
    cursor: pointer;
    &:hover{
      border-bottom: 1px solid;
    }
  }
  .msg{
    text-align: center;
    font-size: 30px;
    margin-top: 20%;
    display: flex;
    justify-content: center;
    .login{
      color: #336498;
      padding: 0 5px;
      border-bottom: 1px solid #336498;
      cursor: pointer;
    }
  }
}
.login{
  position: absolute;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  .e-modal-background {
    background: #ffffff;
    color: #595959;
    position: absolute;
    z-index: 5000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .e-modal-dialog {
    z-index: 6000;
    width: 60%;
    padding: 10px;
    height: 400px;
    max-height: 80%;
    position: absolute;
    left: 20%;
    background: #fff;
    box-shadow: 0 0 10px black;
    padding: 5% 12.5%;
    top: 50%;
    transform: translateY(-50%);
    .user{
      .typing-input{
        width: calc(100% - 90px);
      }
    }
    .password-row{
      padding: 10px 0 0 0;
      .password{
        width: calc(100% - 90px);
      }
    }
  }
  .sub-text{
    width: 80px;
    padding-top: 5px;
  }
  .footer{
    padding: 10px 0;
  }
}