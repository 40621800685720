@import 'scss/variables.scss';

.tabs {
  width: 100%;
}
.tabs2{
  border: 1px solid rgb(204, 204, 204);
  .item{
    cursor: pointer;
    display: inline-block;
    padding: 0 5px;
    &:not(:last-child){
      border-right: 1px solid rgb(204, 204, 204);
    }
    background-color: rgb(221, 221, 221);
    &.active{
      color: $base-color;
      background-color: #fff;
      // &:last-child{
      //   &::after {
      //     height: 1px;
      //     display: block;
      //     width: 10px;
      //     background: #000000;
      //     content: '';
      //     left: calc(100% + 5px);
      //     position: relative;
      //   }
      // }
      // &:first-of-type{
      //   &::after {
      //     height: 1px;
      //     display: block;
      //     width: 10px;
      //     background: #000000;
      //     content: '';
      //     left: -15px;
      //     position: relative;
      //   }
      // }
    }    
  }
}

.tabs2-link{
  .item {
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    &:not(.active){
      color: $base-color;
      border-bottom: 2px solid $base-color;
      font-weight: 600;
    }
  }
}

.tabs2-header{
  .item {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    border: 1px solid rgb(245, 245, 245);
    border-left: 0;
    &:not(:last-child){
      border-right: 1px solid rgb(245, 245, 245);
    }
    padding: 5px;
    color: $base-color;
    text-decoration: underline;
    .item-label{
      background-color: rgb(245, 245, 245);
      width: 100%;
    }
    &.active{
      background-color: #fff;
      .item-label{
        background-color: #fff;
      }
      border-bottom: 0;
      font-weight: 600;
      color: rgb(26, 26, 26);
      text-decoration: none;
    }
    &.inactive{
      text-decoration: none;
      cursor: auto;
      color: rgb(26, 26, 26);
    }
    
  }
}
