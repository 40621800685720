@import 'scss/variables.scss';
.autosize-textarea{
  overflow-y: auto;  
  border: 1.5px solid #595959;
  width: 100%;
  &.focused{
    @extend %tms-control;
  }
  .scroll-bar .track-vertical.show-bar{
    width: 10px !important;
    padding: 0 2px;
  }
  padding: 0.5px 0;
  textarea{
    width: calc(100% - 2px);
    margin-left: 1px;
		resize: none;
		overflow-y: auto;
    &:focus{
      outline: none;
    }
    border: none;
    padding: 1px;
  }
}