.select{
  &.show{
    display: block;
  }
  &.hide{
    display: none;
  }
  .typeahead{
    border: 1px solid #eee;
  }
}
