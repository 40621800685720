@import '../../scss/variables.scss';
.date-picker{
  position: relative;
  .date-picker-input{
    width: 138px;
    top: 5px;
    input{
      width: calc(100% - 30px);
    }
  }
  .fa-calendar{
    margin-left: 3px;
  }
  .rdp{
    position: absolute;
    left: 0;
    top: 30px;
    background: #fff;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    .rdp-nav{
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
      .rdp-nav_button{
        border: 1px solid transparent;
        border-radius: 0;
        padding: 0;
        height: 25px;
        &:hover{
          border: 1px solid transparent;
        }
        &:hover:not([disabled]):not(.rdp-day_selected) {
          background-color: transparent;
        }
        &:focus{
          background-color: transparent;
          border: 2px solid #fff;
        }
      }
    }
    .rdp-months{
      .rdp-month{
        margin: 0;
        margin-top: 0;
        .rdp-caption{
          background-color:$base-color;
          color: #fff;
          text-align: center;
          display: block;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          &::before{
            position: absolute;
            content: '';
            width: 0;
            top: -5px;
            left: 20px;
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;            
            border-bottom: 5px solid $base-color;
          }
        }
      }
    }
    .rdp-day{
      padding: 2px;      
      &:focus{
        outline-style: solid !important;
        outline-color: rgb(77,144,254) !important;
        outline-width: 1.2px !important;
      }
      &.rdp-day_selected{
        background-color: rgb(77,144,254);
      }
      &.rdp-day_today{
        background-color: transparent;
        color: initial;
      }
    }
    .rdp-weekday{
      color: #000;
    }
  }
}