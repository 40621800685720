@import '../../scss/variables.scss';
.more{
  width: 100%;
  .more-container{
    overflow: hidden;
    height: auto;    
  }
  .more-container2{
    margin-bottom: 10px;
  }
  .more-label{
    position: absolute;
    margin-top: -35px;
    right: 10px;
    background-color: #fff;
    cursor: pointer;
    text-decoration: underline;
    color: $base-color;
    font-size: 14px;
    &::before{
      content: "";
      position: absolute;
      left: -40px;
      width: 40px;
      height: 100%;
      background-image: linear-gradient(to right,rgba(255,255,255,0),#fff);
    }
    &:focus{
      &::before{
        border-right: $outline;
      }
    }
    &.less{
      margin-top: -15px;
    }
    // &.less{
    //   margin-top: -15px;
    //   right: auto;
    //   &::before{
    //     left: 0;
    //     width: 0;
    //     border-right: none !important;
    //   }
    // }
  }
}