
@import 'scss/variables.scss';
.confirm{
  .sub-text{
    padding: 20px 5px 20px 10px;
  }
  .footer{
    text-align: center;
    padding: 10px 10px 0 10px;
    button{
      width: 80px;
      margin: 0 10px;
    }
  }
  .e-modal{
    .e-modal-background{
      z-index: $z-index-modal-dialog;
    }
    .e-modal-dialog{
      padding: 10px 10px 0 10px;
    }
    .e-modal-footer{
      border-top: none;
    }
  }
}