
.e-modal-dialog.message5-modal{
  width: 625px;
  height: 325px;
  padding: 15px 30px 10px 30px;
  .red-warning-bar{
    padding: 10px 0 20px 10px;    
    .warning-bar-content{
      color: #1a1a1a;
      flex-direction: column;
      .title{
        margin-top: -3px;
      }
      .offline-message-body{
        padding: 15px 0 0 0;
      }
    }
  }
}