.date-picker2{
    .react-datepicker__input-container.react-datepicker__view-calendar-icon{
        input{
            padding: 6px 10px 5px 5px;
        }
        svg {
            padding: 0;
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: block;
            right: -10px;
            top: 2px;
        }
        svg path {
            fill: #000000;
            d: path("M15.3,245.1c3.6,3.4,7.9,5.1,12.7,5.1h199.9c4.8,0,9.2-1.7,12.7-5.1c3.6-3.4,5.3-7.6,5.3-12.2V58.1c0-4.6-1.8-8.8-5.3-12.2c-3.6-3.4-7.9-5.1-12.7-5.1h-18.3V27.5c0-5.9-2.3-11-6.6-15.4c-4.6-4.2-9.9-6.4-16-6.4h-9.2c-6.1,0-11.4,2.2-16,6.4c-4.3,4.4-6.6,9.5-6.6,15.4v13.2h-54.5V27.5c0-5.9-2.3-11-6.6-15.4c-4.6-4.2-9.9-6.4-16-6.4H69c-6.1,0-11.4,2.2-16,6.4c-4.3,4.4-6.6,9.5-6.6,15.4v13.2H28.3c-5.1,0-9.4,1.7-13,5.1c-3.6,3.4-5.3,7.6-5.3,12.2v174.8C10,237.5,11.7,241.7,15.3,245.1L15.3,245.1z M28,193.5h41v39.4H28V193.5z M28,140.9h41v43.8H28V140.9z M28,93.1h41v39.1H28V93.1z M64.4,27.5c0-1.2,0.5-2.2,1.3-3.2c1-0.7,2-1.2,3.3-1.2h9.2c1.3,0,2.3,0.5,3.3,1.2c0.7,1,1.3,1.9,1.3,3.2v39.4c0,1.2-0.5,2.2-1.3,3.2c-1,0.7-2,1.2-3.3,1.2H69c-1.3,0-2.3-0.5-3.3-1.2c-0.7-1-1.3-1.9-1.3-3.2V27.5L64.4,27.5z M78.1,193.5h45.3v39.4H78.1V193.5z M78.1,140.9h45.3v43.8H78.1V140.9z M78.1,93.1h45.3v39.1H78.1V93.1z M132.6,193.5h45.5v39.4h-45.5V193.5z M132.6,140.9h45.5v43.8h-45.5V140.9z M132.6,93.1h45.5v39.1h-45.5V93.1z M173.3,27.5c0-1.2,0.5-2.2,1.3-3.2c1-0.7,2-1.2,3.3-1.2h9.2c1.3,0,2.3,0.5,3.3,1.2c0.8,1,1.3,1.9,1.3,3.2v39.4c0,1.2-0.5,2.2-1.3,3.2c-1,0.7-2,1.2-3.3,1.2h-9.2c-1.3,0-2.3-0.5-3.3-1.2c-0.8-1-1.3-1.9-1.3-3.2V27.5L173.3,27.5z M187.3,193.5h40.7v39.4h-40.7V193.5z M187.3,140.9h40.7v43.8h-40.7V140.9z M187.3,93.1h40.7v39.1h-40.7V93.1z");
        }
    }
}