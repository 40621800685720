
.typeahead{
    border-radius: 3px;
    padding-left: 1px;
    .custom-dropdown .dropdown .dropdown-display{
        display: flex;
    }
    input{
      border: 0;
      padding-left: 10px;
    }
    .fa-chevron-down{
      padding: 3px 5px;
      margin: 1px;
      border-left: 1px solid #eee;
      color: rgb(206, 212, 218);
      &:hover{
        color: rgb(159, 159, 159);
      }
    }
    &.disable{
      display: flex;
      .selected{
        float: left;
        width: calc(100% - 32px);
        padding: 3px 10px;
        color: rgb(206, 212, 218);
      }
      .fa-chevron-down{
        float: left;
        &:hover{
          color: rgb(206, 212, 218);
        }
      }
    }
  }
  