@import 'ontario-design-system/styles/ds-theme.css';
ul{
    margin: auto;    
}
@media screen and (min-width: 40em){
    ul{
        margin: auto;    
    }       
}
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
//   -webkit-tap-highlight-color: rgba($black, 0); // 5
}
body{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.5;
}
ul{
  li{
    padding:initial;
  }
  ul{
    padding-inline-start: 25px;
    padding-left: 25px;
    margin: 0;
    width: 100%;
  }
}
.ontario-label{
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
}
.ontario-label__flag{
  &::before{
    content: none;
  }
}
.ontario-checkboxes__label{
  margin: 0 0.25rem;
  &:before, &:after{
    width: 28px;
    height: 28px;
  }
}
.textarea2.small-textarea .ontario-textarea{
  min-height: 90px;
}
.img-button.ontario-icon-add-alt, .img-button.ontario-icon-edit{
  color: rgb(8, 83, 148);
}
.img-button.ontario-icon-delete svg{
  height: 20px;
  width: 20px;
}
select{
  background-origin:initial;
  height:auto;
  -webkit-appearance:none;
  -moz-appearance:none;
}
.ontario-form-group{
  display: flex;
  &:last-of-type{
      margin-bottom: 0;
  }
}
.ontario-dropdown {
  background-position: right;
}
.ontario-input{
  margin: 0 0 5px 5px;
  max-width: 100%;
}
.ontario-button{
  font-size: 16px;
  padding: 5px 15px;
  min-width: 7rem;
  &.ontario-button--primary{
    border: 2px solid #06c;
    &:disabled{
      border: 1px solid transparent;
      color: rgb(140, 140, 140);
      background-color: #C2E0FF;
      &:hover{
        background-color: rgb(166, 201, 237);
      }
    }
  }
}
.ontario-radios {
  max-width: 100%;
  .ontario-radios__input:disabled + .ontario-radios__label:after{
    border-color: #595959;
  }
}
.ontario-checkboxes{
  max-width: 100%;
  .ontario-checkboxes__item {
    padding: 0 0 0 20px;
    margin-left: 5px;
    &:last-of-type{
      margin-left: 5px;
    }
  }
}
