

.deletable {
  display: inline-flex;
  .item{
    padding: 2px 2px 2px 5px;
    margin: 2px 0px 2px 2px;
    display: flex;
    background: #ebecf0; // #fff;
    position: relative;
    text-align: left;
    margin-right: 2px;
    margin-top: 1px;
    padding: 1px 5px 1px 5px;
    border-radius: 3.01px;
    border: 1px solid rgba(204,204,204, 70%);
    .label{
      word-break: break-word;
    }
    .deletableItemIcon{
      img{
        width: 10px;
        margin: -5px 0 0 5px;
      }
    }
  }
}