.tms-progress-bar {
    .progress-bar-container {
        border: 1px solid #ccc;
        height: 26px;
        width: 100%;
    }

    .progress-bar {    
        height: 100%;
        width: 0;
        background-color: #0066CC;
        -webkit-transition: width 0.8s;
        transition: width 0.8s;
    }
}