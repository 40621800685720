
@import 'scss/variables.scss';

.message4{
  position: relative;
  .message4-container{
    position: absolute;
    z-index: $z-index-message;    
    display: none;
    padding: 0px;
    min-width: 500px;
    right: 0;
    top: auto;
    margin-top: 5px;
    
    &.show{
      display: flex;
    }
  }
  .item{
    border: 1px solid #ccc;
    background-color: rgb(230, 229, 231);
    border-radius: 10px;
    flex-direction: row;
    .content{
      padding: 0 15px;
      float: left;
    }
    // .close{
    //   position: absolute;
    //   right: 5px;
    //   cursor: pointer;
    //   display: flex;
    //   .fa{
    //     font-size: 18px;
    //     padding: 3px;
    //   }
    // }
  }
}