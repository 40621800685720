.warning-bar-icon{
  svg{
    width: 27px;
    margin-top: -5px;
    padding: 0px 5px; 
  }
}
.warning-bar-content{
  width: calc(100% - 30px);
  display: flex;
}
.red-warning-bar{
  border-left: 5px solid #CD0000;
  background-color: #FCEFF0;
  padding: 5px;
  color: #CD0000;
  img{
    width: 18px;
    margin: -3px 5px 0 0;
  }
}
.blue-warning-bar{
  border-left: 5px solid #1080A6;
  background-color: #E2F0F4;
  padding: 5px;
  img{
    width: 18px;
    margin: 0px 5px 0 0;
  }
}

.yellow-warning-bar{
  border-left: 3px solid #FFD440;
  background-color: #FEF6DC;
  padding: 5px;
  img{
    width: 25px;
    margin: -2px 5px 0 0;
  }
}
.green-warning-bar{
  border-left: 5px solid #118847;
  background-color: #1188471F;
  padding: 20px 10px;
  img{
    width: 18px;
    margin: -2px 5px 0 0;
  }
}