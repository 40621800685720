@import 'scss/variables.scss';
.scroll-bar{
  height: 100%;
  width: 100%;
  >.fa-caret-up,
  >.fa-caret-down{
    font-size: 16px;
    float: right;
    color: rgb(157, 180, 196);
    cursor: pointer;
    position: relative;
    z-index: 1;
    width: 15px;
    height: 15px;
    background: #fff;
    text-align: center;
    margin-left: -15px;
    padding-left: 3px;
    &:hover{
      color: #c8c6c4;
    }
  }
  >.fa-caret-down{
    margin-top: -15px;
  }
  .scroll-area{
    position: relative; 
    height: 100%; 
    width: 100%; 
    &.show-bar{
      &>div:first-child{
        padding-right: 15px !important;
      }
    }
    &>div:first-child{
      -webkit-overflow-scrolling: auto !important;
    }
  }
  .track-vertical{
    width: 0px !important;
    position: absolute;
    overflow: hidden;
    border-radius: 0px;
    background: #fff;
    user-select: none;
    height: 100%;
    padding: 0;
    top: 0px;
    right: 0px;
    &:hover{
      .thumb-vertical{
        background-color: rgb(152, 163, 166);
      }
    }
    &.show-bar{
      width: 15px !important;
      padding: 0 5px;  
    }
    &.show-arrow{
      height: calc(100% - 30px);
      top: 15px;
    }
  }
  .thumb-vertical{
    position: relative;
    display: block;
    width: 8px!important;
    height: auto;
    transform: translateY(0px);
    background-color: rgb(200,198,196); 
    border-radius: 5px;
    &.dragging{
      background-color: rgb(152, 163, 166);
    }
  }
}
