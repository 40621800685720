@import 'scss/variables.scss';
.e-modal-dialog.wizard-modal{
  max-height: 90%;
  width: 750px;
  top: 5%;
  padding: 0 15px;
  .wizard-modal-container{
    height: calc(100% - 30px);
    .wizard{
      height: 100%;
      .step-indicator{
        cursor: pointer;
        position: absolute; 
        top: 50%; 
        transform: translateY(-50%);
        &.pre{
          left: 0;
          padding-left: 10px;
        }
        &.next{
          right: 13px;
          padding-right: 0px;
        }
        .step-indicator-icon{
          padding: 8px 10px 8px 10px;     
          background: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          color: #0066CC;
          img{
            width: 20px;
            color: #0066CC;
          }
          &.right{
            img{
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
        .step-indicator-label{
          text-decoration: underline;
          color: $link-color;
        }
      }
      .wizard-step{
        justify-content:flex-end;
        padding-bottom: 10px;
        font-weight: 600;
        border-bottom: 3px solid #cccccc;
      }
      .wizard-container{
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
        height: 100%;
      }
      .wizard-footer{
        padding: 20px 0px 20px 65px; 
        display: flex; 
        justify-content: space-between;
      }
    }
    }
  } 
