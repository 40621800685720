.hint {
  display: inline-grid;
  display: -ms-inline-grid;
  cursor: pointer;
  padding: 0 0 0 5px;
  svg{
    width: 15px;
  }
  p {
    margin-bottom: 1em;
  }
  .item{
    position: fixed;
    display: none;
    max-height: 360px;
    min-height: 120px;
    margin-top: 27px;
    background-color: #fff;
    border: 1px solid #eee;
    padding: 1rem;
    margin-left: -19px;
    width: 360px;
    z-index: 100;
    flex-direction: column;

    &:before, &:after{
      content: "";
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #eee;
      margin-left: 6px;
      bottom: 100%;
    }
    &:before{
      bottom: 100%;
    }
    &:after{
      bottom: calc(100% - 1px);
      border-bottom: 10px solid #fff;
    }
    &.show{
      display: flex
    }
    .ontario-button {
      margin: 0px;
    }
    .header{
      font-weight: bolder;
      padding-bottom: 0.5rem;
    }
    .footer {
      padding-top: 1rem;
    }
  }

  &.right{
    .item{
      margin-left: -255px;
      &:before, &:after{
        margin-left: 243px;
      }
    }
  }
  &.center{
    .item{
      margin-left: -125px;
      &:before, &:after{
        margin-left: 112px;
      }
    }
  }
  &.up{
    .item{
      &:before, &:after{
        content: "";
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #eee;
        margin-left: 6px;
        top: 100%;
      }
      &:before{
        border-bottom: transparent;
      }
      &:after{
        border-top: 10px solid #fff;
        margin-top: -1px;
        border-bottom: transparent;
      }
    }
    &.right{
      .item{
        margin-left: -255px;
        &:before, &:after{
          margin-left: 243px;
        }
      }
    }
  }
  &.free{
    .item{
      bottom: 5px;
      // max-height: calc(100% - 10px) !important;
      top: 5px;
      // right: 5px;
      margin-top: 0;
      &:before, &:after{
        display: none;
      }
    }
  }
}