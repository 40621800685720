.auto-size-modal{
  max-height: 90%;
  max-width: 100%;
  width: 750px;
  top: 5%;
  &.invisible{
    left: -9000px;
  }
  .auto-size-modal-container{
    height: 100%;
    .auto-size-modal-inner{
      height: 100%;
    }
    .auto-size-modal-scroll-bar{
      &>.fa-caret-up{
        top: 30px
      }
      &>.scroll-area>.track-vertical.show-arrow{
        height: calc(100% - 60px);
        top: 45px;
      }
    }
  }
} 
