
.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;

    input { 
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: #0066cc;
        }
        &:focus + .slider {
            box-shadow: 0 0 1px 1.5px#0066cc;
        }
        &:checked + .slider:before {
            -webkit-transform: translateX(11px);
            -ms-transform: translateX(11px);
            transform: translateX(11px);
        }

        &:disabled {
            pointer-events:none;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #595959;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            left: 3px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 16px;

            &:before {
                border-radius: 50%;
            }
        }
    }

    .toggle-label {
        left: 30px;
        font-size: 12px;
        position: absolute;
        padding-left: 5px;
    }
}

  