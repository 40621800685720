
.custom-dropdown{
  display: inline-block;
  border: none;
  outline: none;
  .dropdown{
    &.scrollable{
      display: flex;
    }
    .dropdown-item {
      cursor: pointer;
    }
    .dropdown-item.disabled{
      cursor: default;
      pointer-events: initial;
    }
    // span[role=button]{
    //   height: 24px;
    // }
    .dropdown-display {
      width: 100%;
      display: inline-block;
      text-align: left;
      padding-left: 4px;
      margin-bottom: 2px;
      &:focus {
        // ODS default outline
        box-shadow: 0 0 0 4px #009ADB;
        outline: 4px solid transparent;
        transition: all 0.1s ease-in-out;
      }
    }
  }
  .dropdown-menu{
    top: auto;
    flex-direction: column;
    &.show{
      display: flex;
    }
    &.pull-up{
      bottom: 100%;
      top: auto;
    }
  }

  &.pull-down-right{
    text-align: right;
    .dropdown-menu{
      left: auto;
      right: 0;
    }
  }
}