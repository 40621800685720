.dropdown-form{
  .dropdown-toggle{
    &::after{
      content: none;
    }
    &.ontario-button--secondary{
      border: none;
      color: #1a1a1a;
      font-weight: inherit;
      border-radius: 0px;
      min-width: 5px;
      padding: 0;
      background-color: transparent;
      margin: 0;
    }
  }
}