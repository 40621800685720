.animate-input-container{
    width: 100%;
    .animate-input {
        position: relative;
        display: flex;
        flex-flow: row;
        overflow: hidden;
        width: 100%;
    
        input {
            border: none;
            outline: none;
            border-bottom: 1.5px solid #595959;
            width: 100%;

            &:focus ~ .underline-animation {
                left: 0;
            }
        }

        .underline-animation {
            transition: all 0.5s;
            display: inline-block;
            bottom: 0;
            left: -100%;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #2a56c6;  
        }
    }
}