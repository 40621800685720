.edit-button{
    background-color: #F5F5F5;
    width: 35px;
    text-align: center;
    margin-bottom: 5px;
    margin: 2px 0 0 -37px;
    height: calc(100% - 10px);
    border-radius: 0;
    svg{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .fa{
        font-size: 20px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
.edit-input{
    .fa{
        top: 25%;
        transform: translateY(-25%);
    }
}
.edit-textarea, .edit-input{
    margin-top: 10px;
    textarea, input{
        width: calc(100% - 5px);
        border-radius: 0;
        padding-right: 35px;
    }
}
.edit-textarea{    
    .edit-button{
        height: calc(100% - 22px);
        position: relative;
        .resizer-boder{
            position: absolute;
            bottom: -14px;
            height: 9px;
            width: 5px;
            background-color:  #F5F5F5;
            right: 12px;
        }
    }
    
    textarea{
        background:linear-gradient(90deg,#fff 0,#fff calc(100% - 35px),#F5F5F5 calc(100% - 35px));
        &::-webkit-scrollbar-corner{
            background-color: transparent;
        }
    }
}