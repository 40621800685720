$window-min-width: 580px;
$base-color: rgb(8, 83, 148);
$work-area-background-color: rgb(251, 251, 251);
$navbar-height: 42px;
$work-area-width: 1200px;
$active-background-color: rgb(0, 102, 204);
$active-color: rgb(251, 251, 251);
$focus-outline-color: rgb(166, 199, 255);
$place-holder-color: #767676;
$active-row-background-color: rgb(255, 249, 230);
$link-color: rgb(8, 83, 148); //#007bff; #0066cc

$goal-list-container-width: 265px;
$goal-other-width: 285px;

$talent-search-left-container-width: 265px;
$talent-search-right-width: 285px;

$talent-list-left-container-width: 265px;
$talent-list-right-width: 285px;
$talent-list-history-width: 550px;

$mentor-list-left-container-width: 315px;
$mentor-list-right-width: 365px;

//-----z-index---------------------------------
$z-index-message: 7000;
$z-index-spinner: 8000;
$z-index-modal: 5000;
$z-index-modal-dialog: 6000;
//---------------------------------------------
$element-border-color: #ced4da;
:export {
  baseColor: $base-color;
  workAreaWidth: $work-area-width;
  activeBackgroundColor: $active-background-color;
  // activeRowBgColor: $active-row-background-color;
  activeColor: $active-color;
  elementBorderColor: $element-border-color;
}
$outline: 1.5px solid $focus-outline-color !important;
%tms-control{
  border: $outline;
  outline: 0;
  // box-shadow: 0 0 0 0.1rem rgba(0,123,255,.25);
}

@mixin ie-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}