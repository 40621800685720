.textarea2{
  width: 100%;
  flex-direction: column;
  .ontario-label__flag{
    &::before{
      content: none;
    }
  }
  .char-count-container{
    // max-width: 48em;
    .char-count{
      float: right;
    }
  }
}