
.typing-input{
  .custom-dropdown{
    width: 100%;
    .dropdown-menu{
      height: 300px;
      width: 100%;
      max-height: 300px;
      padding-bottom: 9px;
    }
  }
  .dropdown-menu{
    top: 100%;
  }
  &.pullUp{
    .dropdown-menu{
      top: auto;
    }
  }
}