@import 'scss/variables.scss';
.content-editable{
	width: 100%;
	.content{
		overflow: hidden;
		word-break: break-word;
		word-wrap: break-word;
		border: 1px solid transparent;
		&:hover{
			overflow: visible;
		}		
	}
	textarea, input{
		width: calc(100% - 2px);
		resize: none;
		overflow-y: auto;
		margin: 1px;
	}
	.editing{
		.char-count{
			width: calc(100% - 70px);
		}
		button:disabled{
			.fa-check{
				color: #595959;
			}
		}
		.fa-check{
			margin: 0;
		}
	}
	.content-container{
		position: relative;
		padding-right: 20px;
		overflow: hidden;
		&.focused {
			border: 1.5px solid $focus-outline-color !important;
		}
		div[role=button] {
			outline: none;
			border: none;
			cursor: pointer;
			bottom: 0;
			height: auto;
			margin: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 22px;
			background-color: #ebecf0;
			font-size: 16px;
			&::before{
				margin-left: 5px;
			}
			&.fa-check{
				width: auto; 
				margin: 0;
			}
		}
		border: 0;
		&.editable{
			&:hover{
				cursor: pointer;
			}
			display: block;
			border: 1px solid #eee;
			min-height: 29px;
			.fa{
				display: block;
			}
		}
		.more{
			.more-container2{
				margin-bottom: 22px;
			}
			.more-label{
				right: 25px;
				font-size: 14px;
				margin-top: -24px;
			}
		}
	}
}