@import 'scss/variables.scss';

.show-more{
	// width: 100%;
	// .indicator{
	cursor: pointer;
	color: $base-color;
	// font-weight: bold;
	.fa{
		font-weight: bold;
		margin-left: 5px;
	}
	// }
}

