.paginate2{
  padding: 5px;
  .pageNo{
    float: left;
  }
  .pageCtrl{
    float: right;
    .clickable-div{
      float: left;
      margin: 0 10px;
    }
    .disable-div{
      float: left;
      margin: 0 10px;
      height: 21px;
      padding: 0 2px;
      visibility: hidden;
    }
  }
}