@import '../../../scss/variables.scss';

.list {
  margin: 0px;
  overflow-y: auto;
  border-bottom: 1px solid #eee;
  margin-bottom: -1px;
  &.list2{
    height: calc(100% - 26px);
  }
  &.simple-list{
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
  ul{
    padding-left: 0px;
    li {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      &:hover {
        cursor: pointer;
      }
      &.item{
        background: #fff;
      }
      &.itemSelected{
        background: $active-row-background-color;
      }
      &.itemChecked{
        background: yellow;
      }
      &.simpler{
        &:hover {
          cursor: auto;
        }
        &.item{
          background: initial;
        }
        &.itemSelected{
          background: initial;
        }
        &.itemChecked{
          background: initial;
        }
      }
      &.simple{
        border-bottom: 0;
        &.itemSelected{
          border: 1px solid lightgoldenrodyellow;
        }
        &:hover {
          background: #ccc;
        }
      }
    }
  }
}

.list-header {
  // padding-left: 5px;
  padding-top: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ul {
    padding-left:0px;
    margin-bottom: 0;
    li {
      display:inline-block;
      padding-right:5px;
      cursor: pointer;
      border: none;
      outline: none;
      .refresh{
        float: left;
        margin-left: 10px;
      }
      &.check-all{
        input{
          cursor: pointer;
          float: left;  
        }  
      }
      .dropdown-item{
        padding: 0 0 0 10px;
        input{
          margin-right: 10px; 
          height: 13px;
        }
      }
    }
    .fa-ellipsis-v {
      color: #545454;
      cursor: pointer;
    }
  }
}
.list-item{
  display: inline-flex;
  width: 100%;
  .checkbox{
    flex: 0 0 25px;
    text-align: center;
    input{
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      display: inline-flex;
    }
  }
  .item{
    width: 100%;
    flex: 1 1 auto;
  }
  .item-with-checkbox{
    width: calc(100% - 25px);
    flex: 1 1 auto;
  }
}