.sortable-item{
  margin: 2px;
  margin-bottom: 5px;
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: 0;
    box-shadow:0 0 0 1px #80bdff;
  }
}