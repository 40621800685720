
@import '../../../scss/variables.scss';

.tree-node {
    .header{
        width: 100%;
        background: transparent;
        display: flex;
        &.tree-item {
            padding: 2px;
            display: flex;
        }
        .collapsed{
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAA3NCSVQICAjb4U/gAAAAJFBMVEX///9wcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDRPAkXAAAADHRSTlMAESIziJmqu8zd7v+91kxoAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFnRFWHRDcmVhdGlvbiBUaW1lADIxLzEyLzEymvNa/wAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAArSURBVAiZY2DAB1iaoAy2XQZQVvRiKIMVLlQ9AU0EpoZjhwLUnEa81iABAFHzB8GYPzdNAAAAAElFTkSuQmCC);
            background-repeat: no-repeat;
            padding-left: 20px;
            margin-top: 5px;
            background-position: center top;
            @include ie-only() {
                background-position: center center;
            }
            cursor: pointer;
        }
        .expanded{
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAA3NCSVQICAjb4U/gAAAAJFBMVEX///9wcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHDRPAkXAAAADHRSTlMAESIziJmqu8zd7v+91kxoAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFnRFWHRDcmVhdGlvbiBUaW1lADIxLzEyLzEymvNa/wAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNui8sowAAAAySURBVAiZY2AgCbA0MDCkgBgcWxlYd4AYjN0B0YvAclrbdxmAGcyrF0OVWxqQZjwDAwA8XgfBciyedgAAAABJRU5ErkJggg==);
        }
    }
}

.tree {  
    li {
        list-style: none;
    }
    ul {
        &.tree-item-parent {
            list-style: none;
            margin: 0;
            padding-left: 20px;
            margin-bottom: 3px;
            width: 100%;
        }
    }
    // li {
    //     padding: 2px;
    //     margin-right: -3px;
    //     display:block;
    // }
    .tree-item {
        cursor: pointer;
        display: flex;
        padding-left: 20px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #fff;
        vertical-align: top;
        &:hover {
            background-color: #ccc !important;
        }
        &.selected {
            background-color: $active-row-background-color !important;
        }
    }

}