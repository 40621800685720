
.e-modal .e-modal-dialog .custom-dropdown .dropdown-menu{
  inset: unset !important;
  transform: translate(0, 31px) !important;
}
.e-modal .e-modal-dialog .custom-dropdown.mt-lang-proficiency-dropdown .dropdown-menu{
  transform: translate(0, 0px) !important;
}
.e-modal .e-modal-dialog .custom-dropdown.download-profile-format .dropdown-menu{
  transform: translate(0, 0px) !important;
}
.e-modal .e-modal-dialog .custom-dropdown.download-performance-format .dropdown-menu{
  transform: translate(0, 0px) !important;
}
.e-modal .e-modal-dialog.rating-modal .custom-dropdown .dropdown-menu{
  transform: translate(0, 0px) !important;
}
.e-modal .e-modal-dialog .part-activities .ods-dropdown .dropdown-menu.show{  
  height: 200px;
  overflow-y: auto;
  inset: unset !important;
  transform: translate(0, 45px) !important;
}
.mentor-list-detail-container .mentor-list-toggle .custom-dropdown.list-sort .dropdown-menu{
  width: 260px;
  margin-right: 100% !important;
}
.e-modal-dialog.add-feed-modal .typing-input .custom-dropdown .dropdown-menu{
  max-height: initial;
  transform: translate(0, 90px) !important;
}
.e-modal-dialog.language-pop-modal .proficiency .ods-dropdown .dropdown-menu{
  transform: translate3d(0px, 31px, 0px) !important;
}
.talent-search-result-list .custom-dropdown.list-sort .dropdown .dropdown-menu{
  width: 260px;
  transform: translate(0px, 22px) !important;
}
.talent-list .talent-list-detail .custom-dropdown.list-sort .dropdown .dropdown-menu{
  width: 260px;
  transform: translate(0px, 22px) !important;
}