.pagination{
  margin-bottom: 0;
  border-radius: 0;
  .active{
    a, span, a:hover, span:hover, a:focus, span:focus {
      z-index: 3;
      color: #fff;
      background-color: #DBE9F5;
      border-color: #DBE9F5;
      cursor: default;
      color: #000;
      font-weight: 600;
      border-radius: 50%;
    }
  }

  li{
    a, span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.42857143;
      text-decoration: none;
      color: #0066cc;
      background-color: #fff;
      // border: 1px solid #ddd;
      margin: 0px 1px;
    }
    &.disabled{
      a{
        color: rgb(105, 105, 105);
        cursor: initial;
      }
    }
  }
}