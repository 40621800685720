.confirm-banner{
  border-left: 5px solid RGB(17, 136, 71);
  background-color: rgb(226, 240,232);
  padding: 10px 5px;
  .fa-check-circle{
    color: RGB(17, 136, 71);
    font-size: 24px;
    padding-right: 10px
  }
  .confirm-banner-content{
    width: calc(100% - 34px);
  }
  .banner-close{
    justify-content: flex-end;
    .button{
      color: #2b5581;
      background-color: transparent;
      border: 0;
      padding-right: 10px;
    }
  }
}