.e-modal-dialog.in-progress-modal{
  top: 10%;
  width: 80%;
  max-width: 90%;
  max-height: 600px;
  height: 60%;
  display: flex;
  flex-direction: column;
  .in-progress-modal-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    font-weight: 400;
  }
}