.talent-warning-orange{
    border-left: 5px solid #FFD440; 
    background-color: #FEF6DC;
    padding: 10px 5px; 
    margin: 10px 0 10px 0;
    .fa-info-circle{
        color: #FFD440; 
        font-size: 20px; 
        padding-right: 10px; 
        margin-top: 3px;
    }
}